<template>
  <v-container fluid class="grey lighten-3">

    <v-card class="mb-5">

        <v-tabs v-model="tab" @change="tabChanged">
          <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <NKWList v-if="tab == 0"></NKWList>
          </v-tab-item>

          <v-tab-item>
            <AddNKWs v-if="tab == 1"></AddNKWs>
          </v-tab-item>

          <v-tab-item>
            <ReviewNKWs v-if="tab == 2"></ReviewNKWs>
          </v-tab-item>

          <v-tab-item>
            <NKWHistory v-if="tab == 3"></NKWHistory>
          </v-tab-item>

          <v-tab-item>
            <NKWSync v-if="tab == 4"></NKWSync>
          </v-tab-item>

          <v-tab-item>
            <NKWDwhWhitelist v-if="tab == 5"></NKWDwhWhitelist>
          </v-tab-item>
        </v-tabs-items>

    </v-card>

  </v-container>
</template>

<script>
export default {
  name: 'NegativeKeywords',

  data () {
    return {
      tab: null,
      items: ['List', 'Add', 'Review', 'History', 'Sync', 'DWH whitelist'],
      lsTab: 'nkw_tab',
    }
  },

  components: {
    AddNKWs: () => import('@/components/negativeKeywords/AddNKWs.vue'),
    NKWDwhWhitelist: () => import('@/components/negativeKeywords/NKWDwhWhitelist.vue'),
    NKWHistory: () => import('@/components/negativeKeywords/NKWHistory.vue'),
    NKWList: () => import('@/components/negativeKeywords/NKWList.vue'),
    NKWSync: () => import('@/components/negativeKeywords/NKWSync.vue'),
    ReviewNKWs: () => import('@/components/negativeKeywords/ReviewNKWs.vue'),
  },

  methods: {
    tabChanged () {
      localStorage.setItem(this.lsTab, JSON.stringify(this.tab))
    },
  },

  created: function () {
    if (localStorage.getItem(this.lsTab)) {
      this.tab = JSON.parse(localStorage.getItem(this.lsTab))
    }
 },
}
</script>

<style scoped>
</style>
